<template>
  <div>
    <!-- Content Row -->

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-input
          v-model="value.email"
          class="w-full mt-4"
          label="Username (Email)"
          type="email"
          v-validate="'required|email'"
          name="email"
          disabled
          autocomplete="username"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('email')">
          {{ errors.first('email') }}
        </span>

        <vs-input
          v-model="value.password"
          class="w-full mt-4"
          label="Password"
          type="password"
          v-validate="{ required: !lodash.isEmpty(value.password_confirmation) }"
          name="password"
          ref="password"
          autocomplete="new-password"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('password')">
          {{ errors.first('password') }}
        </span>

        <vs-input
          v-model="value.password_confirmation"
          class="w-full mt-4"
          label="Confirm Password"
          type="password"
          v-validate="{ required: !lodash.isEmpty(value.password), confirmed: 'password' }"
          data-vv-as="password"
          name="password_confirmation"
          autocomplete="new-password"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('password_confirmation')">
          {{ errors.first('password_confirmation') }}
        </span>

        <vs-input
          v-model="value.name"
          class="w-full mt-4"
          label="Name"
          v-validate="'required'"
          name="name"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('name')">
          {{ errors.first('name') }}
        </span>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'MeGeneral',
  props: {
    value: {
      type: Object,
    },
  },

  data:() => ({
    loaded: false,
  }),
  computed: {
  },
  methods: {
    validate () {
      return this.$validator.validateAll()
    },
  },
}
</script>
