<template>
  <section>
    <vx-card class="cardx thead">
      <form>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2">
            <me-avatar v-model="info" ref="avatar" class="mb-4" />
          </div>
          <div class="vx-col w-full md:w-1/2">
            <me-general v-model="info" ref="general" />
          </div>
        </div>

        <!-- Save & Reset Button -->
        <div class="vx-row mt-6">
          <div class="vx-col w-full mt-4">
            <div class="flex flex-wrap items-center justify-end">
              <vs-button
                class="ml-auto"
                @click.prevent="saveChanges"
                :disabled="locked || !hasErrors"
              >
                Save Changes
              </vs-button>
              <vs-button
                class="ml-4"
                type="border"
                color="warning"
                :disabled="locked"
                @click="resetData"
              >
                Reset
              </vs-button>
            </div>
          </div>
        </div>
      </form>
    </vx-card>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import infoMixin from '@/mixins/infoMixin'
import schemas from '@/utils/schemas'
import MeGeneral from '@/components/form/me/general'
import MeAvatar from '@/components/form/me/avatar'

export default {
  mixins: [infoMixin],
  components: {
    MeGeneral,
    MeAvatar,
  },
  data: () => ({
    loaded: false,
    schema: schemas.me(),
    info: schemas.me(),
  }),
  computed: {
    ...mapGetters('data', ['roles']),

    ...mapGetters('me', {
      $info: 'info',
    }),
  },
  methods: {
    ...mapActions('me', ['fetch', 'update']),

    async _fetch () {
      this.locked = true

      await this.fetch()

      this.locked = false
    },

    async validateForm () {
      const validates = [
        // this.$refs.avatar.validate(),
        this.$refs.general.validate(),
      ]

      const valids = await Promise.all(validates)

      return valids.every((valid) => valid)
    },
    async saveChanges () {
      const valid = await this.validateForm()

      /* eslint-disable */
      if (!valid) {
        this.$vs.notify({
          title: 'Error',
          text: 'Please complete this form',
          iconPack: 'feather',
          icon: 'icon-error',
          color: 'warning',
        })
        return
      }

      this.locked = true

      await Promise.all([this.update({ data: this.info })])
        .then(() => {
          this.$vs.notify({
            title: 'Success',
            text: 'Your profile has been updated',
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Error',
            text: 'Update fail',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger',
          })
        })
        .finally(() => {
          this.locked = false
        })
    },
  },

  async mounted() {
    this._fetch()
  },
}
</script>

<style lang="scss"></style>
